html {
  /* overflow-y: scroll; */
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f7f7f7;
}
.public.topbar {
  height: 40px;
  background: #006d75;
}
.public.topbar p {
  color: #fff;
  line-height: 40px;
  margin: 0;
}
.public.topbar .left {
  float: left;
}
.public.topbar .right {
  float: right;
}
.public.secondbar {
  height: 100px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
}
.public.secondbar .logo {
  width: 244px;
  margin-top: 10px;
}
.public.secondbar form.login,
.public.secondbar .button-login {
  float: right;
  margin: 30px 0;
}
.wrapper {
  /* width: 975px;
  margin: 0 auto; */
  margin: 0 45px;
}
.wrapper.fixed {
  width: 975px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.public.panel {
  border-top: 1px solid lightgray;
  margin-bottom: 30px;
}
.public.panel:first-child {
  border-top: none;
}
.public.panel p {
  line-height: 1.5em;
}
.public.panel .icon {
  width: 70px;
  float: left;
  margin-top: 20px;
}
.public.panel .content {
  margin-left: 100px;
}
.pendaftaran .title {
  text-align: center;
  border-top: 0.6px solid lightgrey;
  border-bottom: 0.6px solid lightgrey;
  padding: 15px 0;
}
.pendaftaran li {
  margin: 8px 0;
}
.pendaftaran p {
  line-height: 1.5em;
}
.pendaftaran .partition-title {
  background-color: #e6e6e6;
  padding: 8px 13px;
}
.pendaftaran .partition-subtitle {
  padding: 0 13px;
}
.pendaftaran .partition-subtitle.line {
  border-bottom: 1px solid lightgrey;
}
.pendaftaran table.form {
  width: 100%;
}
.pendaftaran table.form td.label {
  padding-right: 13px;
  padding-top: 5px;
  text-align: right;
}
.pendaftaran table.form input,
.pendaftaran table.form textarea {
  width: 600px;
}
.pendaftaran table.form textarea {
  resize: vertical;
}
.pendaftaran table.form td {
  padding-bottom: 10px;
  vertical-align: top;
}
.pendaftaran table.form td.date-cell input {
  width: 200px;
}
.pendaftaran table.form p {
  padding-left: 13px;
}
.pendaftaran table.masjid {
  border: 1px solid #333;
  width: 100%;
  border-collapse: collapse;
}
.pendaftaran table.masjid td,
.pendaftaran table.masjid th {
  border: 1px solid #333;
  vertical-align: middle;
}
.user-info {
  float: right;
  margin: 20px 0;
  text-align: right;
}
.user-info button {
  margin-top: 10px;
}

.internal-panel-arrow {
  float: right;
}

.internal-panel {
  margin-bottom: 10px;
}
table.aligntop td {
  vertical-align: top;
}
.alignCenter {
  text-align: center !important;
}
.alignRight {
  text-align: right !important;
}
p.cellTitle {
  border-bottom: 1px solid #b0bec5;
  margin-top: 15px;
}
p.cellTitle.top {
  margin-top: 0;
}
td.noWrap,
th.noWrap {
  white-space: nowrap;
}
.pointer {
  cursor: pointer;
}

.bp3-toast-container {
  position: fixed !important;
}
::placeholder {
  text-transform: none;
}
.force-uppercase {
  text-transform: uppercase;
}
.nostep::-webkit-outer-spin-button,
.nostep::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.nostep {
  -moz-appearance: textfield;
}
.column-options {
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
.column-options .option {
  user-select: none;
}
fieldset.options {
  border: 1px solid #e3e3e3;
}
fieldset.options button {
  margin-right: 5px;
}
